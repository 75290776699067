import React from 'react';
import { graphql } from 'gatsby';
import ListingPage from '../components/ListingPage';

export default ({ data: { cover, posts } }) => (
  <ListingPage
    cover={cover}
    list={posts}
    title="Blog"
    subtitle="Hier finden Sie Aktuelles & Reiseberichte"
  />
);

export const query = graphql`
  query {
    cover: file(relativePath: { eq: "index/cover_img.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1024) {
          src
        }
      }
    }
    posts: allSanityPost {
      totalCount
      edges {
        node {
          title
          _rawExcerpt
          mainImage {
            asset {
              fluid(maxWidth: 2048) {
                ...GatsbySanityImageFluid
              }
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`;
